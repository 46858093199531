@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Roboto);

body {
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  min-width: 960px;
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  background-size: cover;
}

h1 {
  font-size: 59px;
  letter-spacing: -2px;
  line-height: 40px;
  text-align: center;
  font-family: 'Lobster', cursive;
}

h3 {
  font-size: 25px;
  margin: 0.2em 0;
  text-align: center;
  color: #000;
}

h4 {
  margin-bottom: 5px;
}

p, pre {
  margin: 0 0 10px 0;
}

code {
  text-transform: uppercase !important;
  color: #EEE;
  border-bottom: 1px dotted #666;
}

small {
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid #111;
  font-size: 15px;
  padding: 0 5px;
}

strong {
  text-align: center;
  display: block;
  font-family: 'Lobster', cursive;
  font-size: 28px;
  font-weight: normal;
  color: #646464;
}

a {
  color: gray;
  text-decoration: none;
}

a:hover {
  color: #BBB;
}

.topSection {
  background: #000;
}

.header {
  margin-bottom: 52px;
  position: relative;
  width: 100%;
  height: 119px;
  line-height: 118px;
  z-index: 1;
  padding-top: 30px
}

.logo {
  width: auto;
  margin: 0 auto;
  padding: 16px;
  border: 1px dashed #fff;
  height: 125px;
  border-radius: 180px;
}

.wrapper {
  margin: 0 auto;
  position: relative;
  width: 100%;
  z-index: 1;
}

.content {
  padding-bottom: 60px;
  margin-bottom: 60px;
}

.content h2 {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 42px;
  font-weight: 300;
  color: #fff;
}

.content h3 {
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  display: block;
  clear: both;
}

.subscribe p {
  text-align: center;
}

.subscribe input {
  background: rgba(255, 255, 255, 0.52);
  color: #7a7a7a;
  font-size: 19px;
  font-weight: bold;
  vertical-align: middle;
  width: 293px;
  border: 1px solid #000;
  padding: 10px;
  box-sizing: border-box;
  height: 44px;
}

.subscribe input[type="button"] {
  background: rgba(255, 255, 255, 1);
  width: auto;
  padding: 10px 25px;
  cursor: pointer;
  margin-left: -5px;
  font-weight: bold;
  font-size: 13px;
  color: #000;
}

.callback,
.simple {
  font-size: 20px;
  background: #27ae60;
  padding: 0.5em 0.7em;
  color: #ecf0f1;
  margin-bottom: 50px;
  -webkit-transition: background 0.5s ease-out;
  transition: background 0.5s ease-out;
}

.callback {
  cursor: pointer;
}

.ended {
  background: #c0392b;
}

.countdown {
  text-align: center;
  margin-top: 30px;
  height: 30px;
}

#overlay {
  position: fixed;
  top: 0;
  width: 100%;
  bottom: 0;
  opacity: 0.8;
}

footer {
  width: 100%;
  height: 30px;
  background: rgba(0, 0, 0, 0.64);
  position: absolute;
  bottom: 0;
}

footer span {
  float: right;
  margin: 10px;
}

@media (max-width: 768px) {
  body {
    min-width: initial !important;
  }

  .wrapper {
    width: 100%;
  }
}

@media (max-width: 420px) {
  h1 {
    float: initial;
    text-align: center;
    margin-left: 0;
    margin-bottom: 0;
  }



  .content h2 {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 29px;
    font-weight: 300;
  }


  .subscribe input[type="button"] {
    margin-top: 10px;
  }

  .subscribe input {
    width: 80%;
  }

  footer {
    position: relative !important;
  }
}
